<template>
  <div class="ca-page">
    <v-row class="ca-page-title">
      <v-col>
        <h1 class="ca-title">Video Vibes</h1>
        <h2 class="ca-subtitle">
          Which Sokal spot best represents your style?
        </h2>
      </v-col>
    </v-row>
    <v-row class="ca-content">
      <v-col class="ca-spots ca-options">
      <v-row v-for="(chunk, i) in chunkPictures" :key="i">
        <v-col v-for="(col, i) in chunk" sm="6" cols="12" :key="i">
          <spot :option="col.option" :jpg="col.jpg" :gif="col.gif" :video="col.video" :url="col.url"></spot>
        </v-col>
      </v-row>
    </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import Spot from "../components/Spot.vue";
export default {
  data() {
    return {
      spots: [
        {
          option: "thematic",
          jpg: require("../assets/images/spots/thematic_25.png"),
          gif: require("../assets/images/spots/Deal_Card.gif"),
          video: require("../assets/images/spots/videos/deal-card.mp4"),
          url: "https://vimeo.com/1049098974/bd217cb19b"
        },
        {
          option: "kinetic",
          jpg: require("../assets/images/spots/kinetic_25.png"),
          gif: require("../assets/images/spots/Graphic-Centered.gif"),
          video: require("../assets/images/spots/videos/kinetic.mp4"),
          url: "https://vimeo.com/1049071366/eb5ab648f5"
        },
        {
          option: "on location shoot",
          jpg: require("../assets/images/spots/on_location_25.png"),
          gif: require("../assets/images/spots/On_Location_Shoot.gif"),
          video: require("../assets/images/spots/videos/on-location.mp4"),
          url: "https://vimeo.com/683025513/37df81b5d0"
        },
        {
          option: "spokesperson",
          jpg: require("../assets/images/spots/spokesperson_25.png"),
          gif: require("../assets/images/spots/Spokesperson.gif"),
          video: require("../assets/images/spots/videos/spokesperson.mp4"),
          url: "https://vimeo.com/1049343882/9fd8545a29"
        },
      ],
    };
  },
  components: {
    Spot,
  },
  computed: {
    chunkPictures() {
      return _.chunk(this.spots, 2);
    },
  },
};
</script>

<style scoped>
.spot-link-img {
  height: 16px;
  position: relative;
  top: 2px;
}
.spot-link-text {
  font-size: 16px;
  text-decoration: none !important;
  color: #ff4438;
  transition: .3s all ease;
}
.spot-link-text:hover {
  color: #db362c;
}
</style>
